import React from "react"
import { Link } from "gatsby"
import { StaticImage as Img } from "gatsby-plugin-image"

import Newsletter from "./newsletter"

const navigation = {
  main: [
    { name: 'Over Vonk', href: '/over' },
    // { name: 'Verhaal inzenden', href: '/inzenden' },
    { name: 'Bedankt', href: '/bedankt' },
  ],
  legal: [
    { name: 'Contact', href: '/contact' },
    { name: 'Algemene voorwaarden', href: '/voorwaarden' },
    { name: 'Retouren', href: '/retouren' },
  ],
  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/vonkfantasy',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Twitter',
      href: 'https://www.twitter.com/vonkfantasy',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
  ],
}

const NewFooter = () =>  <footer className="mt-8 bg-gray-800">
  <div className="max-w-6xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8 lg:flex lg:space-x-6">
    {/* <div className="flex-0 flex flex-col text-white items-center mb-6 lg:mb-0 justify-center sm:mx-auto sm:max-w-xl">
      <Link to="/plus">
        <Img className="w-48 m-auto" src="../../content/images/vonk+.png" alt="Vonk+" />
      </Link>
      <div>
        <ul className="list-disc list-inside">
          <li>Steun Nederlandstalige fantasyschrijvers</li>
          <li>Lees de verhalen van Vonk Magazine als ebook</li>
          <li>En meer</li>
        </ul>
        Vanaf € 5 per jaar.
      </div>
      <div className="flex mt-2 mb-4">
        <Link className="font-sans cursor-pointer block rounded-md border border-transparent px-5 py-3 bg-teal-500 text-base font-medium text-teal-100 shadow hover:bg-teal-900 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-red-600 sm:px-10" to="/plus">Meld je nu aan</Link>
      </div>
    </div> */}
    <div className="flex-1">
      <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
        {navigation.main.map((item) => (
          <div key={item.name} className="px-5 py-2">
            <Link to={item.href} className="text-base text-gray-100 hover:text-gray-200">
              {item.name}
            </Link>
          </div>
        ))}
      </nav>
      {/* <div className="mt-6">
        <p className="sm:mx-auto sm:max-w-xl text-gray-100 mb-2">Schrijf je in voor de Vonk nieuwsbrief.</p>
        <Newsletter />
      </div> */}
      <div className="mt-8 flex justify-center space-x-6">
        {navigation.social.map((item) => (
          <a key={item.name} href={item.href} target="_blank" rel="noreferrer me" className="text-gray-100 hover:text-gray-200">
            <span className="sr-only">{item.name}</span>
            <item.icon className="h-8 w-8" aria-hidden="true" />
          </a>
        ))}
      </div>
      <nav className="flex flex-wrap justify-center" aria-label="Footer">
        {navigation.legal.map((item) => (
          <div key={item.name} className="px-5 py-2">
            <Link to={item.href} className="text-base text-gray-100 hover:text-gray-200">
              {item.name}
            </Link>
          </div>
        ))}
      </nav>
    </div>
  </div>
</footer>

export default NewFooter;
