import { Link } from "gatsby"
import React from "react"
import { StaticImage as Img } from "gatsby-plugin-image"

const Header = () => {
  return (
    <header
      className="shadow-lg bg-red-600"
    >
      <Link
        className="m-auto block p-3 w-36"
        to="/"
      >
        <Img src="../../content/images/logo-silver.png" placeholder="none" alt="Vonk logo"/>
      </Link>
    </header>
  );
}

export default Header
