/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"

import Header from "./header"
import Footer from "./footer"
import { mq } from "../utils/responsive"

export const Wide = ({children}) => <div className="max-w-6xl mx-auto mt-8">{children}</div>

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="flex flex-col min-h-screen font-serif text-lg bg-gray-50 overflow-x-hidden">
      <Header siteTitle={data.site.siteMetadata.title} />
      <div className="flex-1">
        {children}
      </div>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

export const Page = styled.div`
  max-width: 54rem;
  margin: auto;
  padding-left: .5rem;
  padding-right: .5rem;
`

export const Content = styled.div`
  margin: auto;
  max-width: 54rem;
  padding-left: .5rem;
  padding-right: .5rem;
  ${mq.medium} {  
    padding-left: .5rem;
    padding-right: 14rem;
  }
`
